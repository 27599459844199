<template>
  <div class="journalism">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="box">
      <div class="website">网站建设中</div>
    </div>
    <!-- <div class="navs">
      <div
        v-for="(item, index) in navList"
        :key="index"
        @click="onNav(index)"
        :class="active === index ? 'navActive' : ''"
      >
        {{ item }}
      </div>
    </div>
    <div class="list_box">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="time" @click="toDetails(item)">
          <h4>{{ item.dates[2] }}</h4>
          <span>{{ item.dates[0] + "-" + item.dates[1] }}</span>
        </div>
        <div class="content" @click="toDetails(item)">
          <h3>{{ item.title }}</h3>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="data.page"
        :page-sizes="[3, 5, 10, 20]"
        :page-size="data.size"
        layout=" prev, pager, next"
        :total="list.length"
        :pager-count="4"
      >
      </el-pagination>
    </div> -->
    <Footer2 />
  </div>
</template>

<script>
export default {
  name: "Journalism",
  data() {
    return {
      navList: ["企业动态", "通知公告"],
      active: 0,
      list: [
        {
          title: "国庆节放假通知",
          date: "2022-09-29",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "端午节放假通知",
          date: "2022-05-31",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "夏令时作息时间调整通知",
          date: "2022-05-10",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "夏令时作息时间调整通知",
          date: "2022-05-10",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "国庆节放假通知",
          date: "2022-09-29",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "端午节放假通知",
          date: "2022-05-31",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "夏令时作息时间调整通知",
          date: "2022-05-10",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "夏令时作息时间调整通知",
          date: "2022-05-10",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
        {
          title: "夏令时作息时间调整通知",
          date: "2022-05-10",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻",
        },
      ],
      data: {
        page: 1,
        size: 5,
      },
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/7.png"),
        },
      ],
    };
  },
  created() {
    this.list.map((item, index) => {
      this.list[index].dates = item.date.split("-");
    });
  },
  mounted() {},
  methods: {
    onNav(index) {
      this.active = index;
    },
    handleCurrentChange(val) {
      this.data.page = val;
    },
    toDetails(item) {
      console.log(item);
      this.$router.push("/journalismDetails");
    },
  },
};
</script>

<style scoped lang="scss">
.journalism {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .website {
    height: 200px;
    font-size: 26px;
    line-height: 200px;
    color: #aaa;
  }
  .navs {
    padding: 0 16px;
    margin-top: 20px;
    display: flex;
    > div {
      font-size: 15px;
      color: #333;
      line-height: 30px;
      font-weight: 200;
      cursor: pointer;
      font-family: "AlibabaPuHuiTi-Light";
      width: 70px;
    }
    > div + div {
      margin-left: 20px;
    }
    .navActive {
      position: relative;
      font-family: "AlibabaPuHuiTi-Regular";
      color: #006eff;
      font-weight: 400;
      font-size: 17px;
    }
    .navActive::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 2px;
      background-color: #006eff;
    }
  }
  .list_box {
    padding: 0 16px;
    width: calc(100% - 32px);
    .item {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      padding-bottom: 15px;
      margin-top: 15px;
      .time {
        min-width: 70px;
        height: 70px;
        border: 1px solid #eeeeee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;
        cursor: pointer;
        box-sizing: border-box;
        h4 {
          font-weight: 400;
          font-size: 16px;
          color: #333;
          font-family: "AlibabaPuHuiTi-Regular";
          line-height: 25px;
        }
        span {
          font-size: 13px;
          color: #666;
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          line-height: 26px;
        }
      }
      .content {
        cursor: pointer;
        h3 {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #333;
          text-align: left;
          margin-bottom: 6px;
        }
        p {
          text-align: left;
          line-height: 22px;
          font-weight: 200;
          font-size: 14px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    ::v-deep .el-pagination {
      margin: 15px 0 15px;
      padding: 0;
      .el-pagination__total,
      .number,
      .el-icon {
        font-size: 14px;
        font-family: "AlibabaPuHuiTi-Light";
        font-weight: 200;
      }
      .number,
      .btn-prev,
      .btn-next {
        width: 32px;
        height: 32px;
        line-height: 32px;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
      }
      .number {
        margin: 0 2px;
      }
      .el-icon {
        background-color: #fff;
        font-family: "AlibabaPuHuiTi-Light";
        font-weight: 200;
        font-size: 12px;
      }
      .active {
        color: #fff;
        background-color: #1890ff;
      }
    }
  }
}
</style>
